import React from 'react';

import Layout from 'components/layout';
import ACCOMODATIONS from 'pageUtils/accomodations/locations.json';

import styles from './accomodations-page.module.scss';

const renderAccomodation = (accomodation) => (
  <li key={accomodation.name} className={styles.accomodation}>
    <a target="_blank" rel="noopener noreferrer" href={accomodation.href}><h4>{accomodation.name}</h4></a>
    { accomodation.distance && <p><em>{accomodation.distance} from farm</em></p> }
  </li>
);

export default () => (
  <Layout>
    <div className={styles.infoGroup}>
      <p>We realize that many of you will be making a trip to celebrate with us and we thank you from the bottom of our hearts. Safe travels—we can’t wait to see you!</p>
      <p>The Catskills offer a lot of adventures and beautiful scenery. If you’d like to sleep over for a night or two, here are some suggestions of where to stay. Keep in mind that Livingston Manor is a popular destination during the summer months so we encourage you to book as soon as possible!</p>
    </div>
    <div className={styles.infoGroup}>
      <h5>AirBnb</h5>
      <p>
        <a target="_blank" rel="noopener noreferrer" href={ACCOMODATIONS.airbnb.href}>Airbnb</a> and&nbsp;
        <a target="_blank" rel="noopener noreferrer" href={ACCOMODATIONS.redCottage.href}>Red Cottage</a> (the local Airbnb)&nbsp;
        are great options in the area. Pool together with friends or family and make yourselves at home.</p>
    </div>
    <div className={styles.infoGroup}>
      <h5>Hotels + Bed & Breakfasts</h5>
      <p>There are some lovely boutique hotels and B&Bs in the area. Due to the small size of these properties we were unfortunately not able to reserve room blocks for our guests.</p>
    </div>
    <ul className={styles.accomodationsList}>
      { ACCOMODATIONS.list.map(renderAccomodation) }
    </ul>
  </Layout>
)

// { accomodation.phone && <a href={`tel:${accomodation.phone}`}>Phone</a> }
